@import "styles/variables.scss"; @import "styles/colours.scss"; @import "styles/mixins.scss"; @import "styles/typography.scss";
.link {
  display: flex;
  width: 7.23rem;
  height: 6.75rem;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: $spacing-sm;
  border: 0.125rem solid $fill-inactive-light;
  border-radius: $spacing-xs;

  &:hover {
    color: $label-text;
    text-decoration: none;
  }

  .label {
    @include text-sm;
    @include text-bold;

    color: $label-text;
    white-space: nowrap;
  }

  .image {
    flex-shrink: 0;
  }
}

.spacer {
  height: 4rem;
  flex: 0 0 1px;
  background-color: $fill-inactive-light;
}
